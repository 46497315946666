class TabbedContent {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		this.tabBtns = this.block.querySelectorAll('.tab-btn');
		this.tabs = this.block.querySelectorAll('.tab');
		this.tabContainer = this.block.querySelector('.tab-container');

		// Set the height of the tab container
		this.setHeight();

		// Debounce the resize event
		const resizeHandler = debounce( () => {
			this.setHeight();
		}, 250);
		window.addEventListener('resize', resizeHandler);

		// Add click event listeners to tab buttons
		this.tabBtns.forEach( (btn, index) => {
			btn.addEventListener('click', () => {
				this.toggleTab(index);
			});
		});
	}

	/**
	 * Toggle the active tab
	 * @param {string} index
	 */
	toggleTab(index) {
		this.tabBtns.forEach( btn => btn.classList.remove('active') );
		this.tabs.forEach( tab => tab.classList.remove('active') );

		this.tabBtns[index].classList.add('active');
		this.tabs[index].classList.add('active');

		this.setHeight();
	}

	/**
	 * Set the height of the tab container
	 */
	setHeight() {
		const activeTab = this.block.querySelector('.tab.active');
		const activeTabHeight = activeTab.querySelector('.row').offsetHeight;

		this.tabContainer.style.height = `${activeTabHeight}px`;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-tabbed-content').forEach( block => new TabbedContent(block) );
});
